@import url(./datepicker.css);

@tailwind base;

@tailwind components;
@tailwind utilities;

h1,
h2,
h3,
h4,
h5,
h6 {
  @apply font-rubik;
  @apply text-dats-s5;
}

body {
  @apply font-body;
  @apply text-dats-s5;
}

h1 {
  font-size: 32px;
  @apply font-bold;
  @apply text-dats-s5;
}

h2 {
  @apply text-2xl;
  @apply font-bold;
}

h3 {
  @apply text-lg;
  @apply font-medium;
}

h4 {
  @apply text-base;
  @apply font-medium;
}

h5 {
  @apply text-sm;
  @apply font-medium;
}

h6 {
  @apply text-sm;
  @apply font-bold;
}

a {
  @apply text-inherit;
  @apply text-dats-s3;
  @apply underline;
  @apply font-body;
  @apply font-semibold;
}

#not-found-richtext a {
  @apply text-dats-s3;
  @apply underline;
  @apply font-body;
  @apply text-lg;
  @apply font-normal;
}
/* React toggle */

.react-toggle {
  touch-action: pan-x;

  display: inline-block;
  position: relative;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  padding: 0;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}

.react-toggle-screenreader-only {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.react-toggle--disabled {
  cursor: not-allowed;
  opacity: 0.5;
  -webkit-transition: opacity 0.25s;
  transition: opacity 0.25s;
}

.react-toggle-track {
  width: 50px;
  height: 24px;
  padding: 0;
  border-radius: 30px;
  background-color: #d2d2ca;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #d2d2ca;
}

.react-toggle--checked .react-toggle-track {
  background-color: #95c230;
}

.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #95c230;
}

.react-toggle-track-check {
  position: absolute;
  width: 14px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  left: 8px;
  opacity: 0;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-check {
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle-track-x {
  position: absolute;
  width: 10px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  right: 10px;
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-x {
  opacity: 0;
}

.react-toggle-thumb {
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  position: absolute;
  top: 1px;
  left: 1px;
  width: 22px;
  height: 22px;
  border: 1px solid #d2d2ca;
  border-radius: 50%;
  background-color: #fafafa;

  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

.react-toggle--checked .react-toggle-thumb {
  left: 27px;
  border-color: #95c230;
}

.react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
  -webkit-box-shadow: 0px 0px 5px 5px #0099e0;
  -moz-box-shadow: 0px 0px 5px 5px #0099e0;
  box-shadow: 0px 0px 5px 5px #0099e0;
}

/* Start React slider */
.horizontal-slider {
  width: 100%;
  max-width: 100%;
  height: 24px;
  margin: auto;
}

.track {
  position: relative;
  @apply bg-dats-p1;
}

.track.track-0 {
  background: #D2D2CA;
}

.horizontal-slider .track {
  top: 10px;
  height: 4px;
}

.horizontal-slider .track-0 {
  top: 10px;
  height: 4px;
}

.horizontal-slider .mark {
  top: 6px;
  width: 12px;
  height: 12px;
  background: #D2D2CA;
  border-radius: 100%;
}

.mark.mark-uncompleted {
  @apply bg-dats-p1;
}

.horizontal-slider .thumb {
  cursor: pointer;
  top: 0px;
  width: 24px;
  height: 24px;
  border-radius: 100%;
  background: #fff; 
  border-width: 3px;
  @apply border-dats-p1;
}
/* End React slider */

input[id^='react-select-'] {
  opacity: 1 !important;
}

::-webkit-search-cancel-button {
  appearance: none;
}

:focus-visible {
  outline: none;
}

ol.styled-ol {
  counter-reset: item;
}

li.styled-ol-item {
  @apply relative;
}

li.styled-ol-item > div {
  @apply pl-12;
}

li.styled-ol-item::before {
  content: counter(item) '.'; /* Use the value of the counter as content */
  counter-increment: item; /* Increment the counter */
  width: 34px;
  height: 34px;
  @apply absolute left-0 top-0 flex items-center justify-center rounded-full bg-dats-s1 font-rubik text-white;
}

.ais-InfiniteHits-loadMore::before {
  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='21' height='21' viewBox='0 0 21 21' fill='none'%3E%3Cpath d='M10.4998 3.62109C10.9601 3.62109 11.3332 3.99419 11.3332 4.45443V14.1093L14.9106 10.5318C15.236 10.2064 15.7637 10.2064 16.0891 10.5318C16.4145 10.8573 16.4145 11.3849 16.0891 11.7103L11.0891 16.7103C10.7637 17.0358 10.236 17.0358 9.91059 16.7103L4.91058 11.7103C4.58515 11.3849 4.58515 10.8573 4.91058 10.5318C5.23602 10.2064 5.76365 10.2064 6.0891 10.5318L9.6665 14.1093V4.45443C9.6665 3.99419 10.0396 3.62109 10.4998 3.62109Z' fill='%23005E75'/%3E%3C/svg%3E");
  position: relative;
  display: inline-block;
  transform: translateY(5px);
  margin-right: 5px;
}

.grecaptcha-badge {
  opacity: 0;
}

/* Custom map spinner */
.pos-center {
  position: fixed;
  top: calc(50% - 24px);
  left: calc(50% - 24px);
}

.mapSpinner {
  border: 8px solid #00b5e2;
  border-top: 8px solid #00b5e200;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  animation: spin 750ms linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.custom-break-word {
  word-break: break-word;
}
